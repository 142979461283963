<template>
  <div v-if="show" style="height:100vh;mx-height: 100vh; overflow-y: auto;">
    <Main />

    <OrientationModal />

    <ErrorModal />

    <ConfirmModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Main from './views/main.vue'
import OrientationModal from '@/components/modals/orientationModal.vue'
import ConfirmModal from '@/components/modals/confirmModal.vue'
import ErrorModal from '@/components/modals/errorModal.vue'
import basketService from '@/api-services/basket.service'
import baseService from '@/api-services/base.service'
import Axios from 'axios'
import { WEB_URL } from '@/common/config'

export default {
  name: 'App',
  components: {
    OrientationModal,
    Main,
    ConfirmModal,
    ErrorModal
  },
  data () {
    return {
      iOS: false,
      page: window.location.pathname,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      results: [],
      processModal: false,
      show: false
    }
  },
  computed: {
    ...mapGetters([
      'userData',
    ])
  },
  created () {
    window.addEventListener('load', () => this.checkIsMobile())
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
  },
  mounted () {
    this.getClubFromUrl()
    window.addEventListener(
      'orientationchange',
      this.checkIsMobile()
    )
  },
  methods: {
    // intercepts any unauthorised call on the axios object and logouts
    addAxiosAuthInterceptor () {
      Axios.interceptors.response.use(undefined, (err) => {
        if (err.response && err.response.status === 401) {
          this.$store.commit('storeUserData', { aut: false })
          this.$store.commit('setBasket', [])
          this.$router.push('/home').catch(() => {})
        } else {
          return Promise.reject(err)
        }
      })
    },
    unsubscribe (id) {
      alert(id)
    },
    async tryGetBasket () {
      // checks for token and then if still valid gets and sets the basket
      if (this.userData.access_token) {
        try {
          const res = await basketService.getMyBasket(this.userData.access_token)
          this.$store.commit('setBasket', res.data)
        } catch (err) {
          // if failed assume token is invalid and logout
          this.$store.commit('storeUserData', { aut: false })
        }
      }
    },
    async getDraws () {
      try {
        const res = await baseService.getDrawsThisMonth()
        this.$store.commit('storeNextDrawDate', new Date(res.data.NextDraw))
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
    },
    keyDown: function () {
      this.checkIsMobile()
    },
    handleVuexState () {
      this.$store.commit('storeId', 0)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
      this.$store.commit('clearBasket')
    },
    async getClubFromUrl () {
      if (window.location.pathname !== '/') {
        try {
          const clubUrl = window.location.pathname.replace('/','')
          await baseService.doesClubUrlExists(clubUrl).then((res) => {
            localStorage.setItem('clubUrl', clubUrl)
            if (res.data) {
              this.$router.push({ redirect: window.location.href = WEB_URL + '/#/leaderboard' })
            } else {
              this.$router.push({ redirect: window.location.href = '/' })
            }
          })
        } catch (err) {
          this.$router.push({ redirect: window.location.href = '/' })
        }
      } else {
        this.loadMainPage()
      }
    },
    loadMainPage () {
      this.show = true
      this.addAxiosAuthInterceptor()
      this.handleVuexState()
      this.tryGetBasket()
      this.getDraws()
    },
    checkIsMobile () { 
      // store if device is mobile or not - excluding tablets which we treat as desktop
      if (/mobile/i.test(navigator.userAgent)) {
        if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
          this.$store.commit('storeMobileView', true)
        } else {
          this.$store.commit('storeMobileView', true)
        }
      } else {
        this.$store.commit('storeMobileView', false)
      }
      this.iOS = ['iPhone', 'iPad'].indexOf(navigator.platform) >= 0
      if (/ipad|iPad/i.test(navigator.userAgent)) {
        this.iOS = true
      }
      if (this.iOS) {
        this.$store.commit('storeMobileView', true)
      }
      if (navigator.userAgent.search('Mobile') > 0) {
        this.$store.commit('storeMobileView', true)
      }
      if (navigator.userAgent.search('Android') > 0) {
        this.$store.commit('storeMobileView', true)
      }
      if (navigator.userAgent.search('SamsungBrowser') > 0) {
        this.$store.commit('storeMobileView', true)
      }
      if (/constructor/i.test(window["HTMLElement"]) || (function(p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window["safari"] !== 'undefined' && window["safari"].pushNotification))) {
        if (navigator.userAgent.search('Android') > 0) {
          this.$store.commit('storeSafari', false)
        }
        else if (navigator.userAgent.search('SamsungBrowser') > 0) {
          this.$store.commit('storeSafari', false)
        }
        else {
          this.$store.commit('storeSafari', true)
        }
      }
    }
  }
}
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

$image-path: '~@/../mdb/mdbvue/img';
@import '~@/../mdb/mdbvue/scss/mdb-pro.scss';

@font-face {
  font-family: "GRLFontVersa";
  src: url('../Versa-Versa.woff') format('woff');
}
* {
  font-family: "GRLFontVersa";
  box-sizing: border-box !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
<style>
 @media (min-width: 767px) {
  .mdbvue-sidenav[data-v-2e1b1b43] {
    width: 33.333333% !important;
    background: var(--bg-color);
  }
 }
</style>
