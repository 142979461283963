import Axios from 'axios'
import { API_URL } from '@/common/config'

export default class userService {
  constructor () {
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = API_URL
  }

  // get player transactions
  static async getPlayerTransactions (token, email) {
    return await Axios.get(API_URL + '/api/game/getplayertransactions?email=' + email, { headers: { Authorization: 'Bearer ' + token } })
  }

  // get my heroes
  static async getMyHeroes (showOnlyActive, token) {
    return await Axios.get(API_URL + `/api/game/getmyheroes?showOnlyActive=${showOnlyActive}`, { headers: { Authorization: 'Bearer ' + token } })
  }

  // get my leaderboards
  static async getMyLeaderboards (token) {
    return await Axios.get(API_URL + '/api/game/getmyleaderboards', { headers: { Authorization: 'Bearer ' + token } })
  }
    
  // get my details
  static async getMyDetails (token) {
    return await Axios.get(API_URL + '/api/game/getmydetails', { headers: { Authorization: 'Bearer ' + token } })
  }

  // get my club details
  static async getMyClubSummary (token) {
    return await Axios.get(API_URL + '/api/game/myclubsummary', { headers: { Authorization: 'Bearer ' + token } })
  }

  // edit my details
  static async editMyDetails (token, pwd, player) {
    return await Axios.post(API_URL + '/api/game/editmydetails?pwd=' + pwd, player, { headers: { Authorization: 'Bearer ' + token } })
  }

  // take a break
  static async takeABreak (token, une) {
    return await Axios.put(API_URL + '/api/game/takeabreak?une=' + une, null, { headers: { Authorization: 'Bearer ' + token } })
  }

  // close account
  static async closeAccount (token, une) {
    return await Axios.delete(API_URL + '/api/game/closeaccount?une=' + une, { headers: { Authorization: 'Bearer ' + token } })
  }

  // get pan
  static async getPan (token) {
    return await Axios.get(API_URL + '/api/game/getpan', { headers: { Authorization: 'Bearer ' + token } })
  }

  // switch club
  static async switchClub (token, clubId) {
    return await Axios.get(API_URL + '/api/game/switch?clubId=' + clubId, { headers: { Authorization: 'Bearer ' + token } })
  }

  // unsubscribe
  static async unsubscribe (id) {
    return await Axios.get(API_URL + '/api/game/unsubscribe?id=' + id, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }
  
}
