<template>
  <div class="flex-grow-1" style="overflow: auto;width: 100%; height: auto;">
    <div
      style="text-align:center;width:60%;max-width: fit-content;margin-left: auto;margin-right: auto;margin-bottom: 30px;"
    >
      <img
        v-if="userData.lgo"
        :src="userData.lgo"
        style="max-width:150px;margin: auto 0;"
        class="desktopOnly justify-content-center"
      >
    </div>
    <FlipClockCountdown
      v-if="nextDrawDate"
      v-show="show"
      class="mb-2"
    />
    <PopupMessage
      v-if="userData.cis"
      :popup-message="popupMessage"
      @show="$emit('show')"
    />
    <ul class="list-unstyled pt-sm-3 m-0" style="width:100%;height:fit-content;overflow:auto; border-radius: 5px;">
      <li
        v-for="(item, i) in menuItems"
        v-show="item.boolean && !item.blockUserRoles.includes(userData.rle)"
        :key="i"
        class="navbarMenuItem"
        :class="{'selected': $route.path.includes(item.route), 'dnone' : item.text === 'My Club Leaderboard' && userData.led === false}"
      >
        <a
          class="mdbvue-sidenav__item d-flex align-items-center"
          @click="goToRoute(item.route)"
        >
          <mdb-icon
            style="width: 25px;"
            :icon="item.icon"
            class="mr-3"
          />
          <div
            class="navbarMenuItemText"
          >
            {{ item.text }}
          </div>
        </a>
      </li>
      <li
        v-show="userData.aut"
        class="navbarMenuItem mb-4"
        @click="logout()"
      >
        <a class="mdbvue-sidenav__item d-flex align-items-center">
          <mdb-icon
            style="width: 25px;"
            icon="sign-out-alt"
            class="mr-3"
          />
          <div class="navbarMenuItemText">Logout</div>
        </a>
      </li>
    </ul>
    <div style="font-size:10px;text-align:center;height: fit-content; color: var(--pr-color); width: 100%;margin-bottom: 150px;">
      <div
        class="mb-2 text-wrap"
        style="padding:10px;"
      >
        grassrootslottery.co.uk is brought to you by Club Supporter Ltd and is licensed and regulated by the UK Gambling Commission (Licence 000-053170-R-330534-001)
      </div>
      <div>Club Supporter Ltd, 76 Front Street, Prudhoe</div>
      <div>NE42 5PU, United Kingdom</div>
      <div class="my-2">
        Grassroots Lottery Version 0.0.2
      </div>
      <img src="../../assets/gc-logo.png" style="width: 100%;max-width:310px;margin-top: 0px;display:initial;">
      <div style="padding:10px;color:white;margin-bottom: 20px !important;">
        powered by Cornerways Digital &copy;{{ new Date().getFullYear() }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FlipClockCountdown from '@/components/countdown/flipCountdown.vue'
import PopupMessage from '../layout/popupMessage.vue'
import userService from '@/api-services/user.service'

export default {
  name: 'SideMenu',
  components: { FlipClockCountdown, PopupMessage },
  data () {
    return {
      show: true,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      menuItems: [
        { text: 'Play', route: '/play?page=1', boolean: true, icon: 'play', blockUserRoles: [] },
        { text: 'My Heroes', route: '/myheroes', boolean: true, icon: 'star', blockUserRoles: [] },
        { text: 'Results', route: '/results', boolean: true, icon: 'clipboard', blockUserRoles: [] },
        { text: 'My Club Leaderboard', route: '/leaderboard', boolean: true, icon: 'table', blockUserRoles: [] },
        { text: 'My Account', route: '/myaccount', boolean: true, icon: 'user', blockUserRoles: [''] },
        { text: 'My Club', route: '/myclub', boolean: true, icon: 'medal', blockUserRoles: ['pla'] },
        { text: 'Payment Details', route: '/paymentdetails', boolean: true, icon: 'credit-card', blockUserRoles: [] },
        { text: 'Transactions', route: '/transactions', boolean: true, icon: 'money-bill', blockUserRoles: [] },
        { text: 'Self-Exclusion', route: '/selfexclusion', boolean: true, icon: 'user-slash', blockUserRoles: [] },
        { text: 'Admin', route: '/adminops', boolean: true, icon: 'cog', blockUserRoles: ['pla', 'adm'] }
      ],
      popupMessage: { cssBackgroundColour: 'red', FAiconCode: 'fa fa-exclamation', popupText: 'Payment Alert', buttonText: 'RESOLVE', buttonURL: '/paymentdetails'  },
      myLeaderboards: []
    }
  },
  computed: {
    ...mapGetters([
      'userData', 'nextDrawDate'
    ])
  },
  created () {
    this.iOS = ['iPhone'].indexOf(navigator.platform) >= 0
    if (this.iOS) {
      if (this.windowWidth < 376) {
        this.show = false
      }
    }
    if (this.userData.led) {
      this.getMyLeaderboards()
    }
  },
  methods: {
    async getMyLeaderboards () {
      try {
        const res = await userService.getMyLeaderboards(this.userData.access_token)
        this.myLeaderboards = res.data
        console.log(res.data)
      } catch {
        //?
      }
    },
    goToRoute (route) {
      this.$router.push(route).catch(() => {})
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.$emit('show')
    },
    logout () {
      this.$store.commit('storeUserData', { aut: false })
      this.$store.commit('storeId', 0)
      this.$store.commit('storeHero', 0)
      this.$store.commit('storeSport', 0)
      this.$store.commit('storeColour', '#e1e1e1')
      this.$store.commit('storeNumber', 0)
      this.$store.commit('storeFirstInitial', '')
      this.$store.commit('storeSecondInitial', '')
      this.$store.commit('clubUrl', '')
      this.$store.commit('clearBasket')
      this.$store.commit('storeLoginData', true)
      this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
      this.$emit('logout')
      this.$emit('show')
    }
  }
}
</script>

<style lang="scss">
.navbarMenuItem {
  font-size: 1.2rem;
  border-radius: 10px;
  margin: 0 5px;
  padding: 5px 10px;
  color: var(--pr-color);
}
.navbarMenuItem:hover {
  scale: 1.02;
  transition: .05s;
  color: var(--lt-color);
}
.navbarMenuItem.selected {
  background: var(--pr-color);
  color: var(--bg-color);
}
.mdbvue-sidenav__content {
  background: var(--bg-color);
}
</style>
