import Vue from 'vue'
import Router from 'vue-router'
import store from '../../src/store/index'

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/play',
      name: 'Play',
      component: () => import('@/views/play/playContainer.vue'),
      children: [
        { path: '', component: () => import('@/components/playSections/playSectionRightPanel.vue') },
        { path: 'auth', component: () => import('@/components/authPanel/auth.vue') },
        { path: 'pre', component: () => import('@/components/authPanel/pre.vue') },
        { path: 'checkout', component: () => import('@/components/checkoutPanel/checkout.vue') }
      ]
    },
    {
      path: '/basket',
      name: 'Basket',
      component: () => import('@/views/basket/basketContainer.vue'),
      children: [
        { path: 'auth', component: () => import('@/components/authPanel/auth.vue') },
        { path: 'checkout', component: () => import('@/components/checkoutPanel/checkout.vue') }
      ]
    },
    {
      path: '/myheroes',
      name: 'MyHeroes',
      component: () => import('@/views/myHeroes/myHeroesContainer.vue')
    },
    {
      path: '/results',
      name: 'Results',
      component: () => import('@/views/results/resultsContainer.vue')
    },
    {
      path: '/myclub',
      name: 'MyClub',
      component: () => import('@/views/myClub/myClubContainer.vue')
    },
    {
      path: '/myaccount',
      name: 'MyAccount',
      component: () => import('@/views/myAccount/myAccountContainer.vue')
    },
    {
      path: '/paymentdetails',
      name: 'PaymentDetails',
      component: () => import('@/views/paymentDetails/paymentDetailsContainer.vue')
    },
    {
      path: '/transactions',
      name: 'Transactions',
      component: () => import('@/views/transactions/transactionsContainer.vue')
    },
    {
      path: '/selfexclusion',
      name: 'SelfExclusion',
      component: () => import('@/views/selfExclusion/selfExclusionContainer.vue')
    },
    {
      path: '/sign_up_your_club',
      name: 'SignUpYourClub',
      requiresLogin: false,
      component: () => import('@/views/signUpYourClub/signUpYourClubContainer.vue')
    },
    {
      path: '/adminops',
      name: 'Admin',
      requiresLogin: true,
      component: () => import('@/views/adminops/adminContainer.vue')
    },
    {
      path: '/public',
      name: 'public',
      requiresLogin: false,
      component: () => import('@/views/public/publicContainer.vue'),
      children: [
        { path: 'all_results', component: () => import('@/components/results/resultsWithCard.vue') },
        { path: 'last_result', component: () => import('@/components/results/lastResultWithCard.vue') },
        { path: 'countdown', component: () => import('@/components/countdown/countdownLarge.vue') },
        { path: 'unsubscribe', component: () => import('@/components/unsubscribe.vue') }
      ]
    },
    {
      path: '/leaderboard',
      name: 'LeaderBoard',
      requiresLogin: false,
      component: () => import('@/views/leaderboard/leaderboardContainer.vue')
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('../views/play/playContainer.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && store.state.userData.aut === false) {
    next('/')
  } else if (to.matched.some(record => record.name === 'NotFound')) {
    next({ path: '/play', query: { page: 1 } })
  } else {
    next()
  }
})

export default router
