import Axios from 'axios'
import { API_URL } from '@/common/config'

export default class basketService {
  constructor () {
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = API_URL
  }

  // get basket
  static async getMyBasket (token) {
    return await Axios.get(API_URL + '/api/game/getmybasket', { headers: { Authorization: 'Bearer ' + token } })
  }

  // add to basket
  static async addToBasket (card, token) {
    var amendedCard = card
    if (card.Colour === '#ff0000') { amendedCard.Colour = 1 } // red
    if (card.Colour === '#0000ff') { amendedCard.Colour = 2 } // blue
    if (card.Colour === '#ffff00') { amendedCard.Colour = 3 } // yellow
    if (card.Colour === '#008000') { amendedCard.Colour = 4 } // green
    if (card.Colour === '#ff9900') { amendedCard.Colour = 5 } // orange
    if (card.Colour === '#800080') { amendedCard.Colour = 6 } // purple
    if (card.Colour === '#fa42d2') { amendedCard.Colour = 7 } // pink
    if (card.Colour === '#ffffff') { amendedCard.Colour = 8 } // white
    amendedCard.FirstInitial = this.alhpaNumeric(card.FirstInitial)
    amendedCard.SecondInitial = this.alhpaNumeric(card.SecondInitial)
    return await Axios.post(API_URL + '/api/game/addtobasket', amendedCard, { headers: { Authorization: 'Bearer ' + token } })
  }

  // refresh credits
  static async refreshCredits (token) {
    return await Axios.get(API_URL + `/api/game/refreshcredits`, { headers: { Authorization: 'Bearer ' + token } })
  }

  // cancel basket
  static async toggleCancelBasket (basketId, token) {
    return await Axios.post(API_URL + `/api/game/togglecancelbasket?basketId=${basketId}`, {}, { headers: { Authorization: 'Bearer ' + token } })
  }

  // cancel basket
  static async saveBasketNickname (basketId, nickname, token) {
    return await Axios.post(API_URL + `/api/game/savebasketnickname?basketId=${basketId}&nickname=${nickname}`, {}, { headers: { Authorization: 'Bearer ' + token } })
  }

  // get jwt token
  static getJwtToken (cards, basketIds, immediatePayment, clubId, credits, token) {
    return Axios.get(API_URL + '/api/game/getjwttoken?credits=' + credits + '&immediatePayment=' + immediatePayment + '&clubId=' + clubId + '&cards=' + cards + '&basketIds=' + basketIds, { headers: { Authorization: 'Bearer ' + token } })
  }

  // remove from basket
  static async removeFromBasket (basketId) {
    return await Axios.delete(API_URL + '/api/game/deletebasket?basketId=' + basketId, null, { headers: { } })
  }

  // get clubs
  static async getClubs (search = 'all_clubs', onlyCharities = false) {
    return await Axios.get(API_URL + `/api/game/getclubs?search=${search}&onlyCharities=${onlyCharities}`, { headers: { } })
  }

  // get club
  static async getClub (clubUrl) {
    return await Axios.get(API_URL + `/api/game/getclub?query=${clubUrl}`, { headers: { } })
  }

  // get auth token
  static getAuthToken (token) {
    return Axios.get(API_URL + '/api/game/getauthtoken', { headers: { Authorization: 'Bearer ' + token } })
  }

  // get heroes draw dates
  static getHeroesDrawDates (basketOrdersId, token) {
    return Axios.get(API_URL + `/api/game/getheroesdraws?basketOrdersId=${basketOrdersId}`, { headers: { Authorization: 'Bearer ' + token } })
  }

  // get hero leaderboard anon call
  static getHero (bid, cid) {
    return Axios.get(API_URL + `/api/game/gethero?bid=${bid}&cid=${cid}`, { headers: { } })
  }

  static alhpaNumeric (val) {
    if (val === 'A') { return 1 } // A
    if (val === 'B') { return 2 } // B
    if (val === 'C') { return 3 } // C
    if (val === 'D') { return 4 } // D
    if (val === 'E') { return 5 } // E
    if (val === 'F') { return 6 } // F
    if (val === 'G') { return 7 } // G
    if (val === 'H') { return 8 } // H
    if (val === 'I') { return 9 } // I
    if (val === 'J') { return 10 } // J
    if (val === 'K') { return 11 } // K
    if (val === 'L') { return 12 } // L
    if (val === 'M') { return 13 } // M
    if (val === 'N') { return 14 } // N
    if (val === 'O') { return 15 } // O
    if (val === 'P') { return 16 } // P
    if (val === 'Q') { return 17 } // Q
    if (val === 'R') { return 18 } // R
    if (val === 'S') { return 19 } // S
    if (val === 'T') { return 20 } // T
    if (val === 'U') { return 21 } // U
    if (val === 'V') { return 22 } // V
    if (val === 'W') { return 23 } // W
    if (val === 'X') { return 24 } // X
    if (val === 'Y') { return 25 } // Y
    if (val === 'Z') { return 26 } // Z
  }
}
