/* eslint-disable */
<template>
  <div
    style="background: var(--bg-color);"
    class="p-2"
  >
    <div
      v-if="!large"
      style="font-size: clamp(1.4rem, 1.9vw, 1.5rem);margin-bottom:15px;color: #1FFC35;"
      class="d-flex justify-content-center"
      :style="{'color' : accentColour }"
    >
      NEXT DRAW COUNTDOWN
    </div>
    <div
      v-if="timeData"
      class="d-flex align-items-center justify-content-center flip-clock"
      :class="{'mt-5' : large}"
    >
      <template v-for="data in timeData">
        <span
          v-if="data !== null"
          v-show="data.show"
          :id="data.elementId"
          :key="data.label"
          class="flip-clock__piece d-flex flex-column align-items-center"
          style="font-size: clamp(1.3rem, 1.8vw, 3.5rem);color: #1FFC35;"
          :style="{'color' : accentColour }"
          :class="{ 'scaleUpForLargeClock': large }"
        >
          <span
            v-if="!initialising"
            class="flip-clock__card flip-card"
          >
            <b
              class="flip-card__top"
              style="color: #1FFC35;"
              :style="{'color' : accentColour }"
            >{{ data.current | twoDigits }}</b>
            <b
              class="flip-card__bottom"
              style="color: #1FFC35;"
              :style="{'color' : accentColour }"
              :data-value="data.current | twoDigits"
            />
            <b
              class="flip-card__back"
              style="color: #1FFC35;"
              :style="{'color' : accentColour }"
              :data-value="data.previous | twoDigits"
            />
            <b
              class="flip-card__back-bottom"
              style="color: #1FFC35;"
              :style="{'color' : accentColour }"
              :data-value="data.previous | twoDigits"
            />
          </span>
          <span class="flip-clock__slot white-text" style="font-size: clamp(0.6rem, 1.7vw, 1rem);">{{ data.label }}</span>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
const uuidv4 = require('uuid/v4')
import baseService from '@/api-services/base.service'
import { mapGetters } from 'vuex'

export default {
	name: 'flipCountdown',
	props: {
		stop: {
			type: Boolean
		},
		showDays: {
			type: Boolean,
			required: false,
			default: true
		},
		showHours: {
			type: Boolean,
			required: false,
			default: true
		},
		showMinutes: {
			type: Boolean,
			required: false,
			default: true
		},
		showSeconds: {
			type: Boolean,
			required: false,
			default: true
		},
		labels: {
			type: Object,
			required: false,
			default: function () {
				return {
					days: 'Days',
					hours: 'Hours',
					minutes: 'Minutes',
					seconds: 'Seconds'
				}
			}
		},
		countdownSize: {
			type: String,
			required: false
		},
		labelSize: {
			type: String,
			required: false
		},
		large: {
			type: Boolean,
			default: false
		},
		accentColour: {
			type: String,
			default: "#1FFC35"
		}
	},
	data () {
		const uuid = uuidv4()
		return {
			now: Math.trunc(new Date().getTime() / 1000),
			date: null,
			interval: null,
			initialising: true,
			diff: 0,
			show: true,
			timeData: [{
					current: 0,
					previous: 0,
					label: this.labels.days,
					elementId: 'flip-card-days-' + uuid,
					show: this.showDays,
				},
				{
					current: 0,
					previous: 0,
					label: this.labels.hours,
					elementId: 'flip-card-hours-' + uuid,
					show: this.showHours
				},
				{
					current: 0,
					previous: 0,
					label: this.labels.minutes,
					elementId: 'flip-card-minutes-' + uuid,
					show: this.showMinutes
				},
				{
					current: 0,
					previous: 0,
					label: this.labels.seconds,
					elementId: 'flip-card-seconds-' + uuid,
					show: this.showSeconds
				}
			]
		}
	},
	created () {
		this.initialiseClock()
		this.updateTime()
		if (this.diff !== 0) {
			this.show = true
		}
	},
	computed: {
		seconds() {
			return Math.floor(this.diff % (60))
		},
		minutes() {
			return Math.floor(this.diff % (60 * 60) / 60)
		},
		hours() {
			return Math.floor(this.diff % (60 * 60 * 24) / (60 * 60))
		},
		days() {
			return Math.floor(this.diff / (60 * 60 * 24))
		},
		...mapGetters(['nextDrawDate'])
	},
	watch: {
		nextDrawDate: function (newVal, oldVal) {
			const endTime = this.nextDrawDate
			this.date = Math.trunc(Date.parse(endTime.toString().replace(/-/g, '/')) / 1000)
			if (!this.date) {
					throw new Error("Invalid props value, correct the 'nextDrawDate'")
			}
		},
		now (value) {
			this.diff = this.date - this.now
			if (this.diff <= 0 || this.stop) {
					this.diff = 0
					this.updateTime(3, 0)
			} else {
					this.updateAllCards()
			}
		},
		diff (value) {
			if (value <= 0) {
				this.getDraws()
				this.updateAllCards()
			}
		}
	},
	filters: {
		twoDigits(value) {
			if (value.toString().length <= 1) {
				return '0' + value.toString()
			}
			return value.toString()
		}
	},
	methods: {
		async getDraws () {
			try {
				const res = await baseService.getDrawsThisMonth()
				console.log('draws', res.data)
				this.$store.commit('storeNextDrawDate', new Date(res.data.NextDraw))
				setInterval(() => {
					this.initialiseClock()
				}, 1000)
			} catch (err) {
				this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
			}
		},
		initialiseClock () {
			this.initialising = true
			this.date = Math.trunc(Date.parse(this.nextDrawDate.toString().replace(/-/g, '/')) / 1000)
			this.now = Math.trunc(new Date().getTime() / 1000)
			this.interval = setInterval(() => {
				this.now = Math.trunc(new Date().getTime() / 1000)
				this.initialising = false
			}, 1000)
		},
		updateAllCards() {
			this.updateTime(0, this.days)
			this.updateTime(1, this.hours)
			this.updateTime(2, this.minutes)
			this.updateTime(3, this.seconds)
		},
		updateTime(idx, newValue) {
			if (idx >= this.timeData.length || newValue === undefined) {
				return
			}
			if (window['requestAnimationFrame']) {
				this.frame = requestAnimationFrame(this.updateTime.bind(this))
			}
			const d = this.timeData[idx]
			const val = newValue < 0 ? 0 : newValue
			const el = document.querySelector(`#${d.elementId}`)
			if (val !== d.current) {
				d.previous = d.current
				d.current = val

				if (el) {
					el.classList.remove('flip')
					void el.offsetWidth
					el.classList.add('flip')
				}

				if (idx === 0) {
					const els = el.querySelectorAll('span b');
					if (els) {
						for (let e of els) {
							const cls = e.classList[0]
							if (newValue / 1000 >= 1) {
								if (!cls.includes('-4digits')) {
									const newCls = cls + '-4digits'
									e.classList.add(newCls)
									e.classList.remove(cls)
								}
							} else {
								if (cls.includes('-4digits')) {
									const newCls = cls.replace('-4digits', '')
									e.classList.add(newCls)
									e.classList.remove(cls)
								}
							}
						}
					}
				}
			}
		}
	},
	beforeDestroy() {
		if (window['cancelAnimationFrame']) {
			cancelAnimationFrame(this.frame)
		}
	},
	destroyed() {
		clearInterval(this.interval)
	}
}
</script>

<style lang="scss" scoped>
.flip-clock {
    text-align: center;
    perspective: 600px;
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
}

.flip-clock__piece {
  display: block;
  margin: 0 auto;
}

.flip-clock__slot {
	line-height: 1.5;
	display: block;
}
:root {
	--halfHeight: 0.72em;
	--borderRadius: 0.15em;
}

.flip-card {
	display: block;
	position: relative;
	padding-bottom: var(--halfHeight);
	line-height: 0.95;
}

.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
	display: block;
	height: var(--halfHeight);
	background: #222;
	padding: 0.23em 0.15em 0.4em;
	border-radius: var(--halfHeight) var(--halfHeight) 0 0;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transform-style: preserve-3d;
	width: 2.1em;
	height: var(--halfHeight);
}

.flip-card__top-4digits,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits,
.flip-card__back-4digits::before,
.flip-card__back-4digits::after {
	display: block;
	height: var(--halfHeight);
	background: #222;
	padding: 0.23em 0.15em 0.4em;
	border-radius: var(--halfHeight) var(--halfHeight) 0 0;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transform-style: preserve-3d;
	width: 2.65em;
	height: var(--halfHeight);
}

.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
    position: absolute;
    top: -2%;
    left: 0;
    border-top: solid 1px #000;
    background: #393939;
    border-radius: 0 0 var(--halfHeight) var(--halfHeight);
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
}

.flip-card__back-bottom,
.flip-card__back-bottom-4digits {
    z-index: 1;
}

.flip-card__bottom::after,
.flip-card__back-bottom::after,
.flip-card__bottom-4digits::after,
.flip-card__back-bottom-4digits::after {
    display: block;
    margin-top: -var(--halfHeight);
}

.flip-card__back::before,
.flip-card__bottom::after,
.flip-card__back-bottom::after,
.flip-card__back-4digits::before,
.flip-card__bottom-4digits::after,
.flip-card__back-bottom-4digits::after {
    content: attr(data-value);
}

.flip-card__back,
.flip-card__back-4digits {
    position: absolute;
    top: 0;
    height: 50%;
    left: 0%;
    pointer-events: none;
}

.flip-card__back::before,
.flip-card__back-4digits::before {
    position: relative;
    overflow: hidden;
    z-index: -1;
}

.flip .flip-card__back::before,
.flip .flip-card__back-4digits::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
}

.flip .flip-card__bottom,
.flip .flip-card__bottom-4digits {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
}

@keyframes flipTop {
    0% {
        transform: rotateX(0deg);
        z-index: 2;
    }
    0%,
    99% {
        opacity: 1;
    }
    100% {
        transform: rotateX(-90deg);
        opacity: 0;
    }
}

@keyframes flipBottom {
    0%,
    50% {
        z-index: -1;
        transform: rotateX(90deg) translateZ(0px);
        opacity: 0;
    }
    51% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: rotateX(0deg) translateZ(1px);
        z-index: 5;
    }
}
.scaleUpForLargeClock {
	scale: 2.7;
}
@media (max-width: 576px) {
	.scaleUpForLargeClock {
		scale: 1.7;
	}
}
</style>
